<template>
    <CModal
      :show.sync="boo_modal"
      :no-close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="size"
    >
        <div>
            <CRow>
                <CCol sm="12">
                <input
                    type="hidden"
                    v-model="item.id"
                />
                <CInput
                    label="Nombre"
                    placeholder="Digite nombre"
                    v-model="item.name"
                />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                <CInput
                    label="Apellido"
                    placeholder="Digite Apellido"
                    v-model="item.last_name"
                />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                <CInput
                    label="DNI"
                    placeholder="Digite DNI"
                    v-model="item.dni"
                    maxlength="8"
                    v-on:keypress="isNumber(item.dni)"
                />
                </CCol>
            </CRow>
            <CRow>
               <CCol sm="12">
                <CInput
                    type="number"
                    label="Telefono"
                    placeholder="Digite Telefono"
                    v-model="item.phone"
                    
                />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                <CInput
                    type="email"
                    label="Email"
                    placeholder="Digite Email"
                    v-model="item.email"
                />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                <CInput
                    label="Dirección"
                    placeholder="Digite Dirección"
                    v-model="item.address"
                />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                <CInput
                    label="Descripción"
                    placeholder="Digite Descripción"
                    v-model="item.description"
                />
                </CCol>
            </CRow>
        </div>
        <template #header>
        <h6 class="modal-title">{{title}}</h6>
        <CButtonClose @click="mtd_close"/>
      </template>
      <template #footer>
        <CButton @click="mtd_close" color="danger"><i class="fa fa-times"></i>&nbsp;Cancelar</CButton>
        <CButton @click="mtd_commit" :disabled="cp_button == 1 ? true : false" :class="btnClasses" color="success"><i class="fa fa-check"></i>&nbsp;Aceptar</CButton>
      </template>
    </CModal>
</template>

<script>
export default {
    name: "modal_client",
    data(){
        return{
            vue:{
                estado: 1
            },
        }
    },
    props:{
        title: String, 
        boo_modal:Boolean,
        item:{},
        size:String,
    },
    computed:{
        btnClasses() {
            return [`btn btn-${this.color || 'primary'}`]
        },
        cp_button(){
            if (this.item.name != undefined && this.item.last_name != undefined  && this.item.dni != undefined && this.item.phone != undefined 
                && this.item.email != undefined && this.item.address != undefined && this.item.description != undefined){
                return 0;
            }else{
                return 1;
            }
        }
    },
    methods: {
       isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
      
    },
       mtd_close(){
            this.$emit('close_modal');
        },
        mtd_commit(){
            this.$emit('mtd_action');
        }
    },
}
</script>