<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader> COTIZACIÓN DE LOTE </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <div class="input-group">
                  <label
                    class="col-form-label col-sm-3"
                    style="text-align: left; padding-left: 0px"
                    for="Seleccione Cliente"
                    >Cliente</label
                  >
                  <select
                    placeholder="Seleccione Cliente"
                    v-model="clients.id"
                    class="form-control"
                    @change="load_data_client"
                  >
                    <option value="" disabled>[Seleccion un cliente..]</option>
                    <option
                      v-for="item in data_clients"
                      :value="item.value"
                      v-bind:key="item.label"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                  <div class="input-group-append">
                    <button
                      type="button"
                      v-c-tooltip.hover="{
                        content: `Nuevo Cliente`,
                      }"
                      :class="btnClasses"
                      @click="btn_addclient"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="DNI"
                  horizontal
                  placeholder="DNI"
                  disabled="disabled"
                  v-model="clients.dni"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Telefono"
                  horizontal
                  placeholder="Telefono"
                  disabled="disabled"
                  v-model="clients.phone"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Email"
                  horizontal
                  placeholder="Email"
                  disabled="disabled"
                  v-model="clients.email"
                />
              </CCol>
              <CCol xs="0" sm="0" lg="2"> </CCol>
              <CCol xs="12" sm="12" lg="8">
                <CCard>
                  <table
                    class="
                      table table-striped table-fixed table-hover table-bordered
                    "
                  >
                    <thead class="table-succcess">
                      <tr>
                        <th>
                          <div>Proyecto</div>
                        </th>
                        <th>
                          <div>Etapa/Manzana</div>
                        </th>
                        <th>
                          <div>Lote</div>
                        </th>
                        <th>
                          <div>Metraje</div>
                        </th>
                        <!-- <th>
                          <div>Precio</div>
                        </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="table-success">
                        <td class="">
                          <cSelectForm
                            :options="data_projects"
                            placeholder="Escoja un Proyecto"
                            :value.sync="form_add_project.id"
                            @change="load_streetblocks"
                          />
                        </td>
                        <td class="">
                          <cSelectForm
                            :options="data_streetblocks"
                            placeholder="Escoja una Etapa/Manzana"
                            :value.sync="form_add_streetblocks.id"
                            @change="load_lots"
                            :disabled="vue.estado1 == 1 ? true : false"
                          />
                        </td>
                        <td class="">
                          <cSelectForm
                            :options="data_lots"
                            placeholder="Escoja un Lote"
                            :value.sync="form_add_lots.id"
                            @change="load_info"
                            :disabled="vue.estado2 == 1 ? true : false"
                          />
                        </td>
                        <td class="">{{ footage }} m²</td>
                        <!-- <td class="">S/ {{ quotation.amount }}</td> -->
                      </tr>
                    </tbody>
                  </table>
                </CCard>
              </CCol>
              <CCol xs="0" sm="0" lg="2"> </CCol>
              <CCol xs="0" sm="0" lg="2"> </CCol>
              <CCol xs="12" sm="12" lg="8">
                <CCards
                  icon="fa fa-money-bill-alt"
                  caption="Detalle de cotización"
                  btn_name="ocultar"
                  btn_name1="ocultar"
                  :button="cp_none"
                >
                  <CRow>
                    <CCol sm="6">
                      <label>{{ vue.change_discount == true? 'Descuento en %': 'Descuento en S/' }}</label>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <input
                              type="checkbox"
                              aria-label="Checkbox"
                              :v-model="vue.change_discount"
                              @change="change_type_discount"
                            />
                          </div>
                        </div>
                        <input
                          type="text"
                          :placeholder="vue.change_discount == true? 'Desceuento en %': 'Descuento en S/'"
                          v-model="vue.amount_discount"
                          class="form-control border-form-control"
                          @keyup="process"
                          :disabled="vue.estado3 == 1 ? true : false"
                        />
                      </div>
                    </CCol>
                    <CCol sm="6">
                      <CInput
                        type="number"
                        label="Inicial"
                        placeholder="Digite Inicial"
                        v-model="vue.amount_initial"
                        @keyup="process"
                        :disabled="vue.estado3 == 1 ? true : false"
                      />
                    </CCol>
                  </CRow>
                  <!-- Form 12 Meses -->
                  <CRow v-for="(item, index) in quotation" :key="index">
                    <CCol sm="1" style="padding-top: 2.5em">
                      <input
                        type="radio"
                        :id="index + 1"
                        :value="index + 1"
                        v-model="seleccion"
                      />
                    </CCol>
                    <CCol sm="4">
                      <CInput
                        :label="'Monto Total: '+item.cuotas+' Meses'"
                        placeholder="12 Meses"
                        v-model="item.total"
                        disabled="disabled"
                      />
                    </CCol>
                    <CCol sm="3">
                      <CInput
                        label="Monto pendiente"
                        placeholder="0.00"
                        v-model="item.pending"
                        disabled="disabled"
                      />
                    </CCol>
                    <CCol sm="4">
                      <CInput
                        label="Cuota mensual"
                        placeholder="Cuota mensual"
                        v-model="item.quotation"
                        disabled="disabled"
                      />
                    </CCol>
                  </CRow>

                  <!-- Form 24 Meses -->
                  <!-- <CRow>
                    <CCol sm="1" style="padding-top: 2.5em">
                      <input
                        type="radio"
                        id="2"
                        value="2"
                        v-model="seleccion"
                      />
                    </CCol>
                    <CCol sm="4">
                      <CInput
                        label="Monto Total: 24 Meses"
                        placeholder="24 Meses"
                        v-model="quotation.montototal_month_24"
                        disabled="disabled"
                      />
                    </CCol>
                    <CCol sm="3">
                      <CInput
                        label="-------------------"
                        placeholder="%"
                        v-model="quotation.pending_month_24"
                        disabled="disabled"
                      />
                    </CCol>
                    <CCol sm="4">
                      <CInput
                        label="---------------------------"
                        placeholder="Cuota mensual"
                        v-model="quotation.montomes_month_24"
                        disabled="disabled"
                      />
                    </CCol>
                  </CRow> -->

                  <!-- Form 36 Meses -->
                  <!-- <CRow>
                    <CCol sm="1" style="padding-top: 2.5em">
                      <input
                        type="radio"
                        id="3"
                        value="3"
                        v-model="seleccion"
                      />
                    </CCol>
                    <CCol sm="4">
                      <CInput
                        label="Monto Total: 36 Meses"
                        placeholder="36 Meses"
                        v-model="quotation.montototal_month_36"
                        disabled="disabled"
                      />
                    </CCol>
                    <CCol sm="3">
                      <CInput
                        label="-------------------"
                        placeholder="%"
                        v-model="quotation.pending_month_36"
                        disabled="disabled"
                      />
                    </CCol>
                    <CCol sm="4">
                      <CInput
                        label="---------------------------"
                        placeholder="Cuota mensual"
                        v-model="quotation.montomes_month_36"
                        disabled="disabled"
                      />
                    </CCol>
                  </CRow> -->

                  <CCol sm="12" style="text-align: center">
                    <button
                      type="button"
                      style="padding: 5px; width: 22em"
                      :class="btnClasses"
                      :disabled="button"
                      @click="save_quotation"
                    >
                      <i class="fas fa-save"></i>&nbsp; REGISTRAR
                    </button>
                  </CCol>
                </CCards>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- modal Cliente -->
    <cModalClient
      :title="modal.title"
      :boo_modal="modal.modal_form"
      :item="modal.item"
      @close_modal="close_addclient"
      @mtd_action="mtd_action_client"
    >
    </cModalClient>
  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CCards from "../../components/shared/containers/Cards.vue";
import cModalClient from "../mantenance/modal_client.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper, CModalForm, cSelectForm, CCards, cModalClient },
  data() {
    return {
      prefix: "quotation",
      vue: {
        estado1: 1,
        estado2: 1,
        estado3: 1,
        amount_discount: 0,
        amount_initial: 0,
        amount_pending: 0,
        amounttotal_discount: 0,
        change_discount: false,
        tipo_desc: "Descuento en S/.",
        showhide: Array, // dato para los estados de los botones
      },
      seleccion: "",
      footage:0,
      data_clients: [],
      clients: {
        id: "",
        name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      data_projects: [],
      form_add_project: {
        id: "",
        name: "",
        maps: "",
        description: "",
      },
      data_streetblocks: [],
      form_add_streetblocks: {
        id: "",
        state: "",
      },
      data_lots: [],
      form_add_lots: {
        id: "",
        state: "",
      },
      quotation: {
        // id: "",
        // name: "",
        // footage: "",
        // amount: "",
        // montototal_month_12: "",
        // pending_month_12: "",
        // montomes_month_12: "",
        // montototal_month_24: "",
        // pending_month_24: "",
        // montomes_month_24: "",
        // montototal_month_36: "",
        // pending_month_36: "",
        // montomes_month_36: "",
        // selection: "",
      },
      data_amount_total: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
        item: [],
      },
    };
  },
  computed: {
    cp_none: function () {
      return true;
    },
    button() {
      if (this.clients.id != "" && this.footage != 0 && this.seleccion != "") return false;
      return true;
    },
    btnClasses() {
      return [`btn btn-${this.color || "primary"}`];
    },
  },
  created() {
    this.mtd_getdata_clientes();
    this.mtd_getdata_projects();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata_clientes: function () {
      this.get({
        url: this.$store.getters.get__url + "/client",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_clients = response.data_clients;
        })
        .catch((errors) => {});
    },
    load_data_client: function () {
      this.get({
        url:
          this.$store.getters.get__url + "/client/" + this.clients.id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.clients = response;
        })
        .catch((errors) => {});
    },
    btn_addclient: function () {
      this.modal.modal_form = true;
      this.modal.title = "Nuevo Cliente";
      this.modal.item = [];
    },
    mtd_action_client: function () {
      let cliente;
      cliente = {
        id: this.modal.item.id,
        name: this.modal.item.name,
        last_name: this.modal.item.last_name,
        phone: this.modal.item.phone,
        email: this.modal.item.email,
        address: this.modal.item.address,
        description: this.modal.item.description,
        status: this.modal.item.status,
      };

      this.post({
        url: this.$store.getters.get__url + "/client/store",
        token: this.$store.getters.get__token,
        params: cliente,
      })
        .then((response) => {
          this.mtd_getdata_clientes();
          this.close_addclient();
          this.modal.item = [];

          response.forEach((element) => {
            this.clients.phone = element.Telefono;
            this.clients.email = element.Email;
            this.form_add_cliente.id = element.Id;
          });
        })
        .catch((errors) => {});
    },
    close_addclient: function () {
      this.modal.modal_form = false;
    },
    mtd_getdata_projects: function () {
      this.get({
        url: this.$store.getters.get__url + "/project",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_projects = response.data_project;
        })
        .catch((errors) => {});
    },
    load_streetblocks: function () {
      let project_id;
      project_id = this.form_add_project.id;
      this.get({
        url:
          this.$store.getters.get__url +
          "/projectstreetblock/" +
          project_id +
          "/showxproject",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_streetblocks = response.data;
          this.vue.estado1 = 0;
          this.limpiar();
        })
        .catch((errors) => {});
    },
    load_lots: function () {
      let id;
      id = this.form_add_streetblocks.id;
      this.get({
        url:
          this.$store.getters.get__url +
          "/projectstreetblocklot/" +
          id +
          "/showxprojectstreetblock",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_lots = response.data;
          if (response.data.length >= 1) {
            this.vue.tipo_desc = "Descuento en S/.";
            this.vue.estado2 = 0;
          } else {
            this.vue.tipo_desc = "Descuento en S/.";
            this.limpiar();
          }
        })
        .catch((errors) => {});
    },
    limpiar: function () {
      this.vue.estado3 = 1;
      this.vue.tipo_desc = "Descuento en S/.";
      this.data_amount_total = [];
      this.data_lots = [];
    },
    load_info: function () {
      let id;
      id = this.form_add_lots.id;
      this.get({
        url:
          this.$store.getters.get__url +
          "/projectstreetblocklot/" +
          id +
          "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.state != 3) {
            this.quotation = response.data; 
            this.footage = response.footage.footage;       
            this.vue.tipo_desc = "Descuento en S/.";
            this.vue.change_discount = false;
            this.vue.estado3 = 0;
            this.process();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: "LOTE SEPARADO/VENDIDO",
            });
          }
        })
        .catch((errors) => {});
    },
    change_type_discount: function () {
      this.vue.change_discount = ! this.vue.change_discount;
      this.vue.amount_discount=0;
      this.process();
    },
    process: function () {
        this.quotation.forEach(element => {
          /** descuento */
          let descuento=0;
          if (this.vue.change_discount == true) {
            descuento = parseFloat(element.total * this.vue.amount_discount / 100);
          }else{
            descuento = parseFloat(this.vue.amount_discount);
          }
          element.pending = parseFloat(element.total - descuento - this.vue.amount_initial).toFixed(2);
          element.quotation = parseFloat(element.pending / element.cuotas).toFixed(2);
        });
    },   

    save_quotation: function () {
      let elementos = {
        project_streetblock_lot_id: this.form_add_lots.id,
        client_id: this.clients.id,
        user_id: this.$store.getters.get__user_id,
        amount_initial: this.vue.amount_initial,
        type_discount : this.vue.change_discount === true ? 1:0,
        amount_discount: this.vue.amount_discount,
        selection: this.seleccion,
        state: 1,
        quotation: this.quotation
      };

      // Save Quotation
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/store",
        token: this.$store.getters.get__token,
        params: elementos,
      })
        .then((response) => {
          this.quotation=[];
          this.seleccion = "";
          this.data_streetblocks = [];
          this.vue = {
            estado1: 1,
            estado2: 1,
            amount_discount: "",
            amount_initial: 0,
            amount_pending: 0,
            amounttotal_discount: 0,
          };
          this.clients = {
            id: "",
            name: "",
            last_name: "",
            phone: "",
            email: "",
          };
          this.data_projects = [];
          this.mtd_getdata_projects();
          this.form_add_project.id = undefined;

          this.data_clients = [];
          this.mtd_getdata_clientes();
          this.clients.id = undefined;

          this.limpiar();

          bus.$emit("alert", {
            color: "success",
            message: "REGISTRADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.card-detail {
  background-color: #4db6ac;
}
</style>